import authenticatedInstance from "./AxiosInstance/authenticated";

export const getSellerData = async (sellerId) => {
  try {
    const response = await authenticatedInstance.post(
      process.env.REACT_APP_BASE_URL + "/seller",
      {
        sellerId: sellerId,
      }
    );
    if (response.data?.success) {
      return { status: 200, data: response.data?.data };
    }
    return { status: response?.status, data: null };
  } catch (error) {
    console.log("Some error occured while fetching seller Info!");
    console.log(error.message);
    return { status: error.response.status, data: null };
  }
};

export const getSellerProducts = async (asins, sellerId) => {
  try {
    const response = await authenticatedInstance.post(
      process.env.REACT_APP_BASE_URL + "/seller/getproducts",
      {
        sellerId: sellerId,
        asins: asins,
      }
    );
    if (response.data?.success) {
      return { status: 200, data: response.data?.data };
    }
    return { status: response?.status, data: null };
  } catch (error) {
    console.log("Some error occured while fetching seller Products!");
    console.log(error.message);
    return { status: error.response.status, data: null };
  }
};

export const getKeepaChart = async (asin) => {
  try {
    const res = await authenticatedInstance.post(
      process.env.REACT_APP_BASE_URL + "/getchart",
      {
        range: 90,
        asin: asin,
      },
      {
        responseType: "blob",
      }
    );
    const url = URL.createObjectURL(res?.data);
    return url;
  } catch (error) {
    console.log("Some error occured while fetching keepa chart!");
    console.log(error.message);
  }
};
