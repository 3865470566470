import React, { useRef } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import CustomKeepaTooltip from "./CustomKeepaTooltip";

ChartJS.register(...registerables);

const OfferChart = ({ data }) => {
  const chartRef = useRef(null);
  const epochToLocalTime = (epochTime) => {
    const localDate = new Date(epochTime * 1000);
    return localDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
    });
  };
  const epochToFormattedLocalTime = (epochTime) => {
    const localDate = new Date(epochTime * 1000);
    const time = localDate.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const date = localDate.toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${time} ${date}`;
  };
  const extractLabels = (data) =>
    data?.map((d) => epochToLocalTime(d.time)) || [];
  const extractFormattedLabels = (data) =>
    data?.map((d) => epochToFormattedLocalTime(d.time)) || [];
  const labels = extractLabels(data);
  const formattedLabels = extractFormattedLabels(data);

  const valueFormatter = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + "B";
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "k";
    return num.toFixed(0);
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Offers",
        data: data?.map((item) => item.value),
        borderColor: "#a78bfa",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
        formattedDate: formattedLabels,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        enabled: false,
        mode: "index",
        intersect: false,
        // callbacks: {
        //   label: (context) => {
        //     const label = context.dataset.label || "";
        //     const value = context.raw || 0;
        //     return `${label}: ${valueFormatter(value)}`;
        //   },
        // },
      },
      legend: {
        display: false, // Disable legend to remove labels at the top
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: {
          color: "#1f2937",
          font: {
            size: 10,
          },
          // display: false,
        },
        grid: {
          display: true,
        },
        border: {
          color: "#1f2937",
          width: 1,
        },
      },
      y: {
        ticks: {
          callback: valueFormatter,
          color: "#1f2937",
          maxTicksLimit: 5,
        },
        grid: {
          display: true,
        },
        border: {
          color: "#1f2937",
          width: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  // Create a custom plugin for the vertical line
  const verticalLinePlugin = {
    id: "verticalLine",
    beforeDraw: (chart) => {
      if (chart.tooltip?._active?.length) {
        const activePoint = chart.tooltip._active[0];
        const { ctx } = chart;
        const { x } = activePoint.element;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;

        // Draw vertical line
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([5, 5]); // Create dashed line effect
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = "#9ca3af";
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  return (
    <div
      className="bg-gray-100 flex rounded-lg shadow-sm w-full p-1 mt-3 items-center flex-col relative"
      style={{
        minWidth: "100%",
        height: "170px",
      }}
    >
      {data?.length > 0 ? (
        <>
          <Line
            data={chartData}
            options={chartOptions}
            style={
              {
                // minWidth: "70%",
              }
            }
            ref={chartRef}
            plugins={[verticalLinePlugin]}
          />
          <CustomKeepaTooltip chart={chartRef} />
        </>
      ) : (
        <div className="flex items-center w-full h-full opacity-90 justify-center text-gray-100">
          No data
        </div>
      )}
    </div>
  );
};

export default OfferChart;
