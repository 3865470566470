import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../../redux/actions/AxiosInstance/unAuthenticated";
import { Modal } from "../../components/Modal";
import { toast } from "sonner";
import logo from "../../assets/images/logo.png";
import { handleResetPassword } from "../../redux/actions/auth";
import { LuEye, LuEyeOff } from "react-icons/lu";

function ResetPassword() {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the URL
    const url = window.location.href;

    // Create URLSearchParams object
    let params = new URLSearchParams(url.split("?")[1]);

    // Get token from URL
    let token = params.get("oobCode");
    // Get email from URL
    let email = params.get("email");
    setToken(token);
    setEmail(email);
  }, []); // Empty array means this effect runs once on component mount
  const resetPassword = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    const newpassword = e.target.newpassword.value;
    if (!password || !newpassword) {
      toast.error("Please enter all the required fields!");
      return;
    }
    if (password !== newpassword) {
      toast.error("Passwords are not matching!");
      return;
    }
    if (!token) {
      console.log("Token not found!");
      return;
    }
    // Send a POST request to the server
    try {
      await handleResetPassword(token, password);
      setShowModal(true);
      // navigate("/signin");
    } catch (error) {
      console.log("Some error occured while resetting password");
      console.log(error.message);
    }
  };
  return (
    <>
      <div className="grid place-content-center items-center h-screen w-full">
        <div className="px-3 w-full">
          <img
            src={logo}
            alt="logo"
            className="text-center mx-auto w-[40%] h-auto"
          />
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] mt-4">
            Reset your password
          </p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2">
            Reset your password by providing your email and new password
          </p>
          <form onSubmit={resetPassword}>
            <div className="mt-5">
              <label
                htmlFor="email"
                className="text-[#4E5674] font-[500] font-Inter"
              >
                Your email
              </label>
              <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-1.5 px-3 bg-gray-100">
                <input
                  className="border-none outline-none w-full"
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="Email Address"
                  disabled
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="password"
                className="text-[#4E5674] font-[500] font-Inter"
              >
                New Password *
              </label>
              <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
                <input
                  className="border-none outline-none w-full font-Inter"
                  type={showPassword1 ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  required
                />
                {showPassword1 ? (
                  <LuEyeOff
                    onClick={() => setShowPassword1(false)}
                    className="cursor-pointer text-lg text-gray-600"
                  />
                ) : (
                  <LuEye
                    onClick={() => setShowPassword1(true)}
                    className="cursor-pointer text-lg text-gray-600"
                  />
                )}
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="password"
                className="text-[#4E5674] font-[500] font-Inter"
              >
                Confirm New Password *
              </label>
              <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
                <input
                  className="border-none outline-none w-full font-Inter"
                  type={showPassword2 ? "text" : "password"}
                  name="newpassword"
                  id="newpassword"
                  placeholder="••••••••"
                  required
                />
                {showPassword2 ? (
                  <LuEyeOff
                    onClick={() => setShowPassword2(false)}
                    className="cursor-pointer text-lg text-gray-600"
                  />
                ) : (
                  <LuEye
                    onClick={() => setShowPassword2(true)}
                    className="cursor-pointer text-lg text-gray-600"
                  />
                )}
              </div>
            </div>
            <div className="flex justify-center items-center gap-x-4 mt-5">
              <button
                type="submit"
                className="text-[12px] text-sm w-full cursor-pointer font-semibold font-Inter px-5 rounded-lg py-2 text-white bg-green-500 shadow-navButton hover:shadow-navButton border-[1px]"
              >
                Reset password
              </button>
            </div>
          </form>
          <p
            onClick={() => {
              navigate("/signin");
            }}
            className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-5 flex items-center justify-center gap-x-3"
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675"
                stroke="#475467"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back to log in
          </p>
        </div>
      </div>
      {showModal ? (
        <Modal>
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] ">
            Password reset
          </p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2 w-[90%] mx-auto">
            Your password has been successfully reset.
          </p>
          <p className="font-Inter text-center text-sm text-[#475467] w-[60%] mx-auto">
            Click below to log in.
          </p>

          <button
            onClick={() => {
              navigate("/signin");
            }}
            type="submit"
            className="font-semibold w-2/3 mx-auto font-Inter text-sm mt-6 mb-2 block  rounded-lg transition duration-200 ease-in bg-green-500 py-2.5 text-white"
          >
            Signin
          </button>
        </Modal>
      ) : null}
    </>
  );
}

export default ResetPassword;
